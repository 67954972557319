import React from 'react';
import { Image, Header } from 'semantic-ui-react';
import { Styles, Theme } from '../../res';
import placeholderImage from '../../res/images/course.jpg';
import styled from 'styled-components';

const { f_16_700_text, f_12_400_text } = Styles;

const ContainerW = styled.div`
  padding: 10px;
  background-color: ${Theme.colors.PRIMARY_2};
  border-radius: 8px;
  margin: 5px;
  display: flex;

  /* Media Query for Mobile Responsiveness */
  @media (max-width: 768px) { // Adjust breakpoint (768px) as needed 
    flex-wrap: wrap; 
  }
`;

const NewsCard = ({ data }) => {
    const { title, content, imgUrl, extUrl } = data;

    return (
        <a href={extUrl || undefined} target='_blank' rel='noreferrer' className='tw-h-full tw-w-full focus:tw-no-underline hover:tw-no-underline' >
            <div className='tw-bg-GREY_05 tw-p-2 tw-rounded-2xl tw-gap-5 tw-h-full tw-flex'>
                <img src={imgUrl || placeholderImage} className='md:tw-h-full md:tw-w-auto tw-w-20 tw-h-20 md:tw-object-cover tw-rounded-xl' />
                <div className='tw-col-span-2 md:tw-h-full md:tw-overflow-y-auto'>
                    <Header style={f_16_700_text}>{title}</Header>
                    <Header.Subheader style={f_12_400_text}>{content}</Header.Subheader>
                </div>
            </div>
        </a>

    );
};

export default NewsCard;