import React from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';

import AttendanceBadge from './AttendanceBadge';
import avatarImage from '../../res/images/avatar.svg';
import logoImage from '../../res/images/logobluenotext.svg';
import calanderImage from '../../res/images/calander_icon.svg';

const BadgeBlock = ({ data }) => {
    const { imgUrl, userImgUrl, userNameAr, userNameEn, releasedAt, courseName } = data;
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';

    return (
        <div className='tw-border tw-border-GREY_10 tw-p-2 tw-rounded-2xl'>
            <div className='tw-grid md:tw-grid-cols-2 tw-items-center tw-gap-10 tw-rounded-xl tw-p-5 tw-bg-PRIMARY_05'>

                <div className='tw-grid md:tw-grid-cols-2 tw-gap-5 tw-text-sm'>
                    <div className='tw-mb-10 tw-col-span-2 tw-text-center md:tw-text-start'>
                        <h1 className='tw-text-PRIMARY_50 tw-font-bold tw-text-xl'>{courseName}</h1>
                        <p>{`${t("BadgeBlockText", { user: isArabic ? userNameAr : userNameEn, course: courseName })}`}</p>
                    </div>

                    <div className='tw-flex tw-flex-col tw-items-center'>
                        <Avatar src={userImgUrl || avatarImage} round size={42} className='tw-bg-WHITE' />
                        <p>{t("Recipient")}</p>
                        <p className='tw-font-bold tw-m-0'>{isArabic ? userNameAr : userNameEn}</p>
                    </div>
                    <div className='tw-flex tw-flex-col tw-items-center'>
                        <Avatar src={logoImage} round size={42} className='tw-bg-PRIMARY_50' />
                        <p>{t("Provider")}</p>
                        <p className='tw-font-bold tw-m-0'>{t("Learning-go")}</p>
                    </div>
                    <div className='tw-flex tw-flex-col tw-items-center'>
                        <Avatar src={calanderImage} round size={42} />
                        <p>{t("IssueDate")}</p>
                        <p className='tw-font-bold tw-m-0'>{new Date(releasedAt).toLocaleDateString()}</p>
                    </div>
                    <div className='tw-flex tw-flex-col tw-items-center'>
                        <Avatar src={calanderImage} round size={42} />
                        <p>{t("ExpirationDate")}</p>
                        <p className='tw-font-bold tw-m-0'>{t("DoesNotExpire")}</p>
                    </div>
                </div>

                <div className='tw-place-items-center md:tw-place-items-end'>
                    <div className='md:tw-w-60 lg:tw-w-96'>
                        <AttendanceBadge isCompleted imgUrl={imgUrl} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BadgeBlock;