import CustomStore from 'devextreme/data/custom_store';
import i18next from 'i18next';
import request from '../../api';


export const rolesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/access_control/roles/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});



export const allResourcesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/access_control/resources/fullinfo${params}`);
            const resourcesWithDefaultPermissions = data.map(r => ({ ...r, permissions: { ADD: false, EDIT: false, DELETE: false, READ: false, EXPORT: false } }));
            return { data: resourcesWithDefaultPermissions, totalCount };
        } catch (err) {
            return err;
        }
    },
});



export const permissionsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data } = await request.get(`/access_control/permissions/fullinfo${params}`);
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const featuresDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { features, allCount: totalCount } } = await request.get(`/supscription/features/fullinfo${params}`);
            return { data: Object.values(features), totalCount };
        } catch (err) {
            return err;
        }
    },
});


export const packagesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { packages, allCount: totalCount } } = await request.get(`/supscription/packages/fullinfo${params}`);
            return { data: packages, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const packagesCustomDataLookup = (coursesIds) => new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            if (coursesIds) params += `coursesIds=${coursesIds.join(",")}&`;
            params = params.slice(0, -1);
            const { data: { packages, allCount: totalCount } } = await request.get(`/supscription/packages/fullinfo${params}`);
            return { data: packages, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const packageCertsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { packages, allCount: totalCount } } = await request.get(`/supscription/packages/certificate/fullinfo${params}`);
            return { data: packages, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const packageCourseDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { packages, allCount: totalCount } } = await request.get(`/supscription/packages/course/fullinfo${params}`);
            return { data: packages, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const packageUnitsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { packages, allCount: totalCount } } = await request.get(`/supscription/packages/units/fullinfo${params}`);
            return { data: packages, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const promotionDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { promotions, allCount: totalCount } } = await request.get(`/supscription/promotions/fullinfo${params}`);
            return { data: promotions, totalCount };
        } catch (err) {
            return err;
        }
    },
});


export const promotionsTypesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async () => {
        try {
            const data = [{ id: 1, type: 'One_Time', value: i18next.t('Onetime') }, { id: 2, type: 'Period_Based', value: i18next.t('Period') }];
            return { data, totalCount: 2 };
        } catch (err) {
            return err;
        }
    },
});


export const certsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation', 'filter'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { certificates: data, allCount: totalCount } } = await request.get(`/certificates/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});


export const coursesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation', 'filter'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { courses: data, allCount: totalCount } } = await request.get(`/courses/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});


export const unitsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation', 'filter'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { units, allCount: totalCount } } = await request.get(`/units/fullinfo${params}`);
            return { data: Object.values(units), totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const topicsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation', 'filter'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { topics, allCount: totalCount } } = await request.get(`/topics/fullinfo${params}`);
            return { data: Object.values(topics), totalCount };
        } catch (err) {
            return err;
        }
    },
});


export const sizesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/questions/sizes/fullinfo${params}`);
            return { data: Object.values(data), totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const categoriesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/questions/categories/fullinfo${params}`);
            return { data: Object.values(data), totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const difficultiesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/questions/difficulties/fullinfo${params}`);
            return { data: Object.values(data), totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const formatsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/questions/formats/fullinfo${params}`);
            return { data: Object.values(data), totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const usersDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { users, allCount: totalCount } } = await request.get(`/students/fullinfo${params}`);
            return { data: users, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const adminsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { users, allCount: totalCount } } = await request.get(`/admins/fullinfo${params}`);
            return { data: users, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const examsDataLookup = new CustomStore({
    key: 'examId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params += 'onlyParents=true&';
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/exams/fullinfo${params}`);
            return { data: data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const userSubscriptionsDataLookup = (userId) => new CustomStore({
    key: 'idSubscription',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        if (!userId) return { data: [], totalCount: 0 };
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation'].forEach(function (i) {
                if (i in loadOptions && loadOptions[i]) {
                    params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                }
            });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/supscriptions/user/${userId}${params}`);
            return { data: data, totalCount };
        } catch (err) {
            return err;
        }
    },
});



export const questionUsedTypesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const data = [
                { id: 1, text: i18next.t("BankExams"), value: 'BANK' },
                { id: 2, text: i18next.t("FrequentlyExams"), value: 'FREQ' },
                { id: 3, text: i18next.t("MockExams"), value: 'MOCK' },
                { id: 4, text: i18next.t("BankAndFreqExams"), value: 'BANK,FREQ' },
                { id: 5, text: i18next.t("FreqAndMockExams"), value: 'MOCK,FREQ' },
                { id: 6, text: i18next.t("MockAndBankExams"), value: 'BANK,MOCK' },
                { id: 7, text: i18next.t("BankAndFreqAndMockExams"), value: 'BANK,MOCK,FREQ' },
            ];
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const examTypesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const data = [
                { id: 1, text: `${i18next.t("TestKnowledge")}`, value: 'Test_Knowledge' },
                { id: 2, text: i18next.t("Bank"), value: "Bank" },
                { id: 3, text: i18next.t("FrequentlyExams"), value: 'Freq' },
                { id: 4, text: i18next.t("MockExams"), value: 'Mock' },
            ];
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const questionTypesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const data = [
                { id: 1, text: i18next.t("MCQS"), value: 'MCQ' },
                { id: 2, text: i18next.t("Essay"), value: 'ESSAY' },
                { id: 3, text: i18next.t("Tbs"), value: 'COMPOSITE' },
                { id: 4, text: i18next.t("File"), value: 'AFILES' },
            ];
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const paymentTypesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const data = [
                { id: 1, text: i18next.t("MANUAL_CASH"), value: 'MANUAL_CASH' },
                { id: 2, text: i18next.t("WUNION"), value: 'WUNION' },
                { id: 3, text: i18next.t("TRANSFER"), value: 'TRANSFER' },
                { id: 4, text: i18next.t("PAYPAL"), value: 'PAYPAL' },
            ];
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const instructorsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/instructors/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const questionsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'filter', 'sort', 'courseId', 'unitId', 'topicId']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/questions/fullinfo${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const blogDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/blogs${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const blogCategoriesDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/categories/blog${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const blogTagsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/tags${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const packagePoliciesDataLookup = new CustomStore({
    key: 'policyId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/policy${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});


export const packageCategoriesDataLookup = new CustomStore({
    key: 'categoryId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/supscription/package/category${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const packagesTagsDataLookup = new CustomStore({
    key: 'id',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            const data = [
                { id: 1, text: `${i18next.t("DEMO")}`, value: 'DEMO' },
                { id: 2, text: i18next.t("FREE"), value: "FREE" },
                { id: 3, text: i18next.t("PAID"), value: 'PAID' },
                { id: 4, text: i18next.t("TOP_SELL"), value: 'TOP_SELL' },
                { id: 4, text: i18next.t("PREFERRED"), value: 'PREFERRED' },
                { id: 4, text: i18next.t("GOLD_PROMIS"), value: 'GOLD_PROMIS' },
                { id: 4, text: i18next.t("SILVER_PROMIS"), value: 'SILVER_PROMIS' },
                { id: 4, text: i18next.t("CONT_PROMIS"), value: 'CONT_PROMIS' },
            ];
            return { data, totalCount: data.length };
        } catch (err) {
            return err;
        }
    },
});

export const certAttendaceDataLookup = new CustomStore({
    key: 'certAttendId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/cert-attend${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});

export const badgesDataLookup = new CustomStore({
    key: 'badgeId',
    byKey: async (key) => { },
    load: async (loadOptions) => {
        try {
            let params = '?';
            ['skip', 'take', 'searchValue', 'searchExpr', 'searchOperation']
                .forEach(function (i) {
                    if (i in loadOptions && loadOptions[i]) {
                        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
                    }
                });
            params = params.slice(0, -1);
            const { data: { data, allCount: totalCount } } = await request.get(`/admin/badges${params}`);
            return { data, totalCount };
        } catch (err) {
            return err;
        }
    },
});
