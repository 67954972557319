import React from 'react';
import TextBlock from './TextBlock';
import VideoBlock from './VideoBlock';
import GalleryBlock from './GalleryBlock';
import FileBlock from './FileBlock';
import { Consts } from '../../../res';

const Block = (props) => {

    switch (props.data.type) {
        case Consts.BLOCK_EDITOR_BLOCKS_TYPES.TEXT_BLOCK:
            return <TextBlock  {...props} />;

        case Consts.BLOCK_EDITOR_BLOCKS_TYPES.VIDEO_BLOCK:
            return <VideoBlock {...props} />;

        case Consts.BLOCK_EDITOR_BLOCKS_TYPES.GALLERY_BLOCK:
            return <GalleryBlock {...props} />;

        case Consts.BLOCK_EDITOR_BLOCKS_TYPES.FILE_BLOCK:
            return <FileBlock {...props} />;

        default:
            return null;
    }
};

export default Block;