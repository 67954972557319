import React from 'react';
import { Segment, Icon, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import DataGrid, { Column, Editing, Form, Lookup } from 'devextreme-react/data-grid';
import { Item } from 'devextreme-react/form';
import { FilesUploader } from '../../../components';
import { Styles, Theme } from '../../../res';

const { f_20_700_text } = Styles;

const FileBlock = ({ data, saveBlock }) => {
    const { t } = useTranslation();
    const files = data?.files || [];

    return (
        <Segment >
            <DataGrid
                dataSource={files}
                showBorders
                columnAutoWidth
                onSaved={(e) => saveBlock(data.blockId, { ...data, files: e.component.getDataSource().items() })}
            >
                <Editing
                    mode='row'
                    useIcons
                    allowDeleting
                    allowAdding
                >
                    <Form>
                        <Item itemType="group" colCount={2} colSpan={2}>
                            <Item isRequired dataField='nameEn' />
                            <Item isRequired dataField='nameAr' />
                            <Item dataField="type" colSpan={2} />
                            <Item isRequired dataField='urlEn' colSpan={2} />
                        </Item>
                    </Form>
                </Editing>

                <Column dataField='nameEn' caption={t("EnglishName")} />
                <Column dataField='nameAr' caption={t("ArabicName")} />

                <Column dataField='type' caption={t("Type")}>
                    <Lookup
                        dataSource={[
                            { text: t('PDF'), value: 'PDF' },
                            { text: t('Excel'), value: 'MS_EXCEL' },
                            { text: t('Word'), value: 'MS_WORD' },
                            { text: t('PowerPoint'), value: 'MS_POWERPOINT' }
                        ]}
                        valueExpr='value'
                        displayExpr='text'
                    />
                </Column>

                <Column
                    dataField="urlEn"
                    alignment='center'
                    allowSorting={false}
                    allowFiltering={false}
                    caption={t('File')}
                    cellRender={({ value }) => value && <a href={value} target='_blank' rel='noreferrer'><Icon size='big' name='file' link /></a>}
                    editCellRender={({ value, setValue, row }) => row && <FilesUploader isSingle isGeneral data={{ value, setValue }} />}
                />
            </DataGrid>
        </Segment>
    );
};

export const FileBlockViewer = ({ data }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";
    return (
        <div className='tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-5 tw-flex-wrap'>
            {
                data?.files?.map(f => <a href={f && f.urlEn} rel='noreferrer' target='_blank' className='hover:tw-no-underline focus:tw-no-underline'>
                    <Header
                        style={{ ...f_20_700_text, color: Theme.colors.PRIMARY_50, cursor: 'pointer' }}
                        content={isArabic ? f.nameAr : f.nameEn || t('File')}
                        icon={f?.type === 'PDF' ? 'file pdf' : f?.type === "MS_WORD" ? "file word" : f?.type === "MS_EXCEL" ? "file excel" : f?.type === "MS_POWERPOINT" ? "file powerpoint" : "file"}
                        color={f?.type === 'PDF' ? 'red' : f?.type === "MS_WORD" ? "blue" : f?.type === "MS_EXCEL" ? "green" : f?.type === "MS_POWERPOINT" ? "orange" : "blue"}
                        disabled={!f}
                    />
                </a>)
            }
        </div>
    );
};

export default FileBlock;