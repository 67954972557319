import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Image, Button, Header, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { htmlToText } from 'html-to-text';
import { HtmlEditor } from 'devextreme-react/html-editor';
import { BuyingButton } from '..';
import stringShorten from '../../helpers/stringShorten';
import { Styles, Theme, Consts } from '../../res';
import placeholderImage from '../../res/images/course.jpg';
import PATHS from '../../Routers/PATHS';
import { Media } from '../../Media';

import goldenPromise from '../../res/images/promises/golden.svg';
import silverPromise from '../../res/images/promises/silver.svg';
import contPromise from '../../res/images/promises/cont.svg';


const { solidBtn, f_16_700_text, f_16_400_text } = Styles;

const CourseCard = ({ data }) => {
    const { t } = useTranslation();
    const { id, name, coverImgUrl, priceUSD, discountUSD, idSubscriptionPackage, subscrPkgTags, subscrPkgFeatureTxt } = data;
    const [selected, setSelected] = useState(false);

    const isDemo = subscrPkgTags?.includes(Consts.PACKAGES_TAGS.DEMO);
    const isTopSeller = subscrPkgTags?.includes(Consts.PACKAGES_TAGS.TOP_SELL);
    const isFav = subscrPkgTags?.includes(Consts.PACKAGES_TAGS.PREFERRED);
    const isGolden = subscrPkgTags?.includes(Consts.PACKAGES_TAGS.GOLD_PROMIS);
    const isSilver = subscrPkgTags?.includes(Consts.PACKAGES_TAGS.SILVER_PROMIS);
    const isCont = subscrPkgTags?.includes(Consts.PACKAGES_TAGS.CONT_PROMIS);

    const showBanner = (isDemo || isFav || isTopSeller) || (isGolden || isCont || isSilver);


    const getPricing = () => {
        if ((priceUSD - discountUSD) <= 0) return 0;
        return discountUSD ? `$${(priceUSD - discountUSD).toFixed(0)}` : `$${(priceUSD).toFixed(0)}`;
    };

    return (
        <div className='animationCardScale first:tw-my-0 tw-mt-8 tw-w-full tw-break-inside-avoid tw-grid tw-place-items-center'>
            <Card
                style={{ margin: 0, padding: 8, borderRadius: 8, boxShadow: 'none', backgroundColor: selected ? Theme.colors.PRIMARY_50 : Theme.colors.WHITE, cursor: 'pointer' }}
                onMouseEnter={() => setSelected(true)}
                onMouseLeave={() => setSelected(false)}
            >

                <Image loading="lazy"
                    src={coverImgUrl || placeholderImage}
                    label={getPricing() !== 0 ? null : { ribbon: true, style: { ...f_16_400_text, color: Theme.colors.WHITE, backgroundColor: Theme.colors.SECONDARY_50 }, content: t('Free').toUpperCase() }}
                    style={{ display: 'flex', height: 150, width: null }}
                    fluid
                />

                {showBanner && <Card.Content style={{ marginTop: 10, border: 'none', backgroundColor: selected ? Theme.colors.WHITE : isDemo ? Theme.colors.GREY_30 : isTopSeller ? Theme.colors.SECONDARY_50 : isFav ? Theme.colors.PRIMARY_50 : "" }}>
                    <div className='tw-grid tw-grid-cols-3 tw-items-center'>
                        <div className='tw-col-span-2'>
                            <Header style={{ ...f_16_700_text, color: selected ? Theme.colors.PRIMARY_50 : Theme.colors.WHITE }}>
                                {
                                    isDemo ? t("DEMO") : isTopSeller ? t("TOP_SELL") : isFav ? t("PREFERRED") : ""
                                }
                            </Header>
                        </div>
                        <div className='tw-col-span-1 tw-relative '>
                            <Popup basic header={isGolden ? t("GoldenPromise") : isCont ? t("ContinuousPromise") : isSilver ? t("SilverPromise") : ""} content={isGolden ? t("GoldenPromiseDesc") : isCont ? t("ContinuousPromiseDesc") : isSilver ? t("SilverPromiseDesc") : ""} trigger={
                                (isGolden || isCont || isSilver) && <div className='tw-absolute -tw-translate-x-1/2 tw-z-10 tw-w-20 tw-h-20 tw-border-GOLD tw-border tw-pt-0.5 -tw-translate-y-1/2  tw-top-1/2 tw-left-1/2 tw-bg-white tw-rounded-full'>
                                    <img className='tw-cursor-pointer tw-rounded-full' src={isGolden ? goldenPromise : isSilver ? silverPromise : isCont ? contPromise : null} alt="promise" />
                                </div>
                            } />
                        </div>

                    </div>
                </Card.Content>}

                <Card.Content style={{ borderTop: 'none', paddingBottom: 5, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <Card.Header style={{ ...f_16_700_text, color: selected ? Theme.colors.WHITE : Theme.colors.BLACK, whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: 'auto', overflow: 'hidden' }}>
                        {name}
                    </Card.Header>

                    <Card.Content style={{ border: 'none' }}>
                        <HtmlEditor readOnly value={subscrPkgFeatureTxt} style={{ color: selected && Theme.colors.WHITE, border: 'none', boxShadow: 'none', padding: 0, margin: 0 }} />
                    </Card.Content>

                    <Card.Description>
                        <div
                            style={{ display: 'flex', gap: '.5vw', alignItems: 'center', justifyContent: 'space-between' }}
                        >
                            <Button as={Link} size='small' to={`${PATHS.LANDING_COURSES.URL}/${id}/${idSubscriptionPackage}/desc`} content={t('Details').toUpperCase()} style={{ ...solidBtn, backgroundColor: Theme.colors.PRIMARY_05, color: Theme.colors.PRIMARY_50 }} fluid />
                            <BuyingButton mode="PRICE_AS_BUTTON" selectedForHover={selected} data={data} />
                        </div>
                    </Card.Description>
                </Card.Content>
            </Card>
        </div>
    );
};

export default CourseCard;
