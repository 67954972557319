import React from 'react';
import { Grid, GridColumn, Header, Image, Segment, Button, Progress, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Consts, Styles } from '../../res';
import courseImage from '../../res/images/course.jpg';
import PATHS from '../../Routers/PATHS';
import history from '../../history';
import SocialMediaList from '../Lists/SocialMediaList';
import inActiveBadgeIcon from '../../res/images/inactiveBadgeIcon.svg';
import activeBadgeIcon from '../../res/images/activeBadgeIcon.svg';


const { f_16_700_text, solidBtn, basicBtn, f_14_400_text, f_12_700_text } = Styles;

const StudyCard = ({ onChangeCourse, course, getCourseDetails }) => {
    const { t, i18n } = useTranslation();
    const isArabic = i18n.language === "ar";

    const isDemo = course?.subscrPackageTags?.includes(Consts.PACKAGES_TAGS.DEMO);
    const isCompleted = (Math.floor(course?.courseProgress) >= course?.badgeCourseProgress) || false;
    const isBadge = course?.badgeId;

    return (
        <Segment
            basic
            style={{
                background: 'linear-gradient(0deg, #F3F4FB, #F3F4FB), #F3F4FB',
                borderRadius: 8,
            }}
        >
            <Grid columns={3} stackable verticalAlign='middle'>
                <GridColumn width={4}>
                    <Image loading="lazy"
                        src={course.coverImgUrl || courseImage}
                        style={{ borderRadius: 8 }}
                    />
                </GridColumn>

                <GridColumn width={4}>
                    <Header style={{ ...f_16_700_text }}>{course.name}</Header>
                    <Header style={{ ...f_14_400_text, margin: 0 }}>{course.certificateName}</Header>
                    <Header style={{ ...f_12_700_text, margin: 0, marginTop: 5 }}>{`${t('ExpireOn')} ${moment(course.subscrExpireAt).locale(i18n.language).format("dddd Do MMMM YYYY")}`}</Header>
                </GridColumn>

                <GridColumn width={8} textAlign='right'>
                    <div className='tw-relative tw-py-5'>
                        {isBadge && <img style={{ right: isArabic && `${course?.badgeCourseProgress || 0}%`, left: !isArabic && `${course?.badgeCourseProgress || 0}%` }} alt='badge' src={isCompleted ? activeBadgeIcon : inActiveBadgeIcon} className={`tw-absolute tw-w-5 tw-top-2`} />}
                        <Progress progress percent={parseInt(course.courseProgress)} size='small' color='blue' />
                        {isBadge && <p style={{ right: isArabic && `${course?.badgeCourseProgress || 0}%`, left: !isArabic && `${course?.badgeCourseProgress || 0}%` }} className={`${isCompleted ? "tw-bg-yellow-500" : "tw-bg-GREY_30"} tw-rounded-md tw-text-xs tw-text-WHITE tw-absolute tw-p-0.5 -tw-mx-1.5 tw-bottom-7`} >{t("Badge")}</p>}
                    </div>
                    {/* <Divider hidden />
                    <Divider hidden /> */}

                    {isDemo && <Button
                        style={basicBtn}
                        as={Link}
                        to={course?.certificateId ? `/certifications/${course.certificateId}/packages` : `/courses/${course?.id}/${course?.subscrPackageId}/desc`}
                    >{t('BuyCourse').toUpperCase()}
                    </Button>}

                    <Button
                        style={solidBtn}
                        onClick={() => {
                            onChangeCourse(course);
                            getCourseDetails(course.id);
                            history.push(`${PATHS.USER_COURSE_DETAILS.URL}?courseId=${course.id}`);
                        }}
                    >{t('ContinueStudying').toUpperCase()}
                    </Button>

                    {isDemo && <div className='tw-p-2 tw-mt-5 tw-bg-GREY_05 tw-rounded-lg tw-flex tw-flex-row tw-items-center tw-gap-2 tw-w-fit tw-ms-auto'>
                        <div><Icon name='exclamation circle' color='red' /></div>
                        <p className='tw-font-bold tw-text-xs'>{t("TrailText")}</p>
                    </div>}
                </GridColumn>

                {course?.subscrPackageSocialUrls && <GridColumn width={16}>
                    <SocialMediaList data={course.subscrPackageSocialUrls} />
                </GridColumn>}
            </Grid>
        </Segment>
    );
};

export default StudyCard;