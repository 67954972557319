import React from 'react';
import badgeImage from '../../res/images/inactive_badge.png';
import completeBadgeImage from '../../res/images/active_badge.png';
import { Theme } from '../../res';

const AttendanceBadge = ({ progress, isCompleted, imgUrl }) => {
    const comProgress = 100 - progress;

    const keyframes = `
    @keyframes fill${progress} {
     from { transform: rotate(0deg); top:100%; }
     to { transform: rotate(360deg); top: ${comProgress}%; }
    }`;

    if (isCompleted) return (
        <div className='tw-relative'>
            <img
                alt='badge'
                src={imgUrl || completeBadgeImage}
                className='tw-h-full tw-w-full'
            />
        </div>
    );

    return (
        <div className='tw-relative'>
            <style>{keyframes}</style>
            <img
                alt='badge'
                src={badgeImage}
                className='tw-h-full tw-w-full'
            />

            <div style={{ clipPath: "polygon(0% 0%, 100% 0%, 100% 88%, 50% 100%, 0% 88%)" }} className='tw-absolute tw-bg-PRIMARY_05 tw-top-[23.5%] tw-left-[25.6%] tw-right-[26.5%] tw-bottom-[15%]'>
                <div style={{ clipPath: "polygon(0% 0%, 100% 0%, 100% 88%, 50% 100%, 0% 88%)" }} className='tw-relative tw-h-full tw-scale-90 tw-w-full tw-overflow-hidden'>
                    <div style={{ animation: `fill${progress} 7s cubic-bezier(0.47, 0, 0.745, 0.715) forwards` }} className='tw-absolute tw-w-[400px] tw-h-[400px] -tw-left-[80%] tw-bg-PRIMARY_50 tw-rounded-[30%]' />
                    <p style={{ top: `${progress <= 15 ? 80 : comProgress}%`, color: progress > 15 ? Theme.colors.WHITE : Theme.colors.BLACK }} className='tw-text-center tw-z-10  tw-absolute tw-h-full tw-w-full tw-font-bold tw-text-xl'>{progress}%</p>
                </div>
            </div>
        </div>
    );
};

export default AttendanceBadge;