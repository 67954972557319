import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { RequirePermission, RequireAuth } from '../middlewares/HOC';
import PATHS from './PATHS';

import {
    AdminDashboard, AdminAdmins, AdminUsers, AdminSubscriptions, AdminCerts, AdminCourses, AdminUnits, AdminTopics,
    AdminQuestionsMcqsNew, AdminSizes, AdminCategories, AdminDifficulties, AdminOneDayCourses, AdminPackages,
    AdminRoles, AdminPromotions, AdminQuestionsEssay, AdminQuestionsMcqs, AdminQuestionsText, AdminQuestionsComposite,
    AdminQuestionsFile,
    AdminNews, AdminInstructors, AdminSuccessStories, AdminUploadVideos, AdminUploadBooks,
    AdminPaymentsReport, AdminManualPayments, AdminContentReports, AdminUserPerformance, AdminUsersPerformances,
    AdminIpLogs, AdminExams, AdminMockExams, AdminExamCorrection, AdminCoursesCalendar, AdminMessages, AdminNotifications,
    AdminHonorRoll, AdminPolicyTermsSettings,
    UserNotifications, Profile, AdminTeamMembers, AdminPackagesPolicies, AdminPackagesCategories,
    AdminBlogs, AdminBlogsCategories, AdminBlogsTags, AdminFAQs,
    AdminCompanies,
    AdminCertAttendance, AdminCertUserAttendance, AdminBadges, AdminUserBadges,
} from '../pages';

const AdminStack = () => {

    return <Switch>
        <Route path={PATHS.ADMIN_DASHBOARD.URL} exact component={RequirePermission(AdminDashboard, PATHS.ADMIN_DASHBOARD.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_ADMINS.URL} exact component={RequirePermission(AdminAdmins, PATHS.ADMIN_ADMINS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_NEWS.URL} exact component={RequirePermission(AdminNews, PATHS.ADMIN_NEWS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_INSTRUCTORS.URL} exact component={RequirePermission(AdminInstructors, PATHS.ADMIN_INSTRUCTORS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_TEAM_MEMBERS.URL} exact component={RequirePermission(AdminTeamMembers, PATHS.ADMIN_TEAM_MEMBERS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_FAQS.URL} exact component={RequirePermission(AdminFAQs, PATHS.ADMIN_FAQS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_SUCCESS_STORIES.URL} exact component={RequirePermission(AdminSuccessStories, PATHS.ADMIN_SUCCESS_STORIES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_ROLES.URL} exact component={RequirePermission(AdminRoles, PATHS.ADMIN_ROLES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_USERS.URL} exact component={RequirePermission(AdminUsers, PATHS.ADMIN_USERS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_SUBSCRIPTIONS.URL} exact component={RequirePermission(AdminSubscriptions, PATHS.ADMIN_SUBSCRIPTIONS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_PROMOTIONS.URL} exact component={RequirePermission(AdminPromotions, PATHS.ADMIN_PROMOTIONS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_PACKAGES.URL} exact component={RequirePermission(AdminPackages, PATHS.ADMIN_PACKAGES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_PACKAGES_POLICIES.URL} exact component={RequirePermission(AdminPackagesPolicies, PATHS.ADMIN_PACKAGES_POLICIES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_PACKAGES_CATEGORIES.URL} exact component={RequirePermission(AdminPackagesCategories, PATHS.ADMIN_PACKAGES_CATEGORIES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_CERTIFICATIONS.URL} exact component={RequirePermission(AdminCerts, PATHS.ADMIN_CERTIFICATIONS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_COURSES.URL} exact component={RequirePermission(AdminCourses, PATHS.ADMIN_COURSES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_ONE_DAY_COURSES.URL} exact component={RequirePermission(AdminOneDayCourses, PATHS.ADMIN_ONE_DAY_COURSES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_UNITS.URL} exact component={RequirePermission(AdminUnits, PATHS.ADMIN_UNITS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_TOPICS.URL} exact component={RequirePermission(AdminTopics, PATHS.ADMIN_TOPICS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_QUESTIONS_MCQS_NEW.URL} exact component={RequirePermission(AdminQuestionsMcqsNew, PATHS.ADMIN_QUESTIONS_MCQS_NEW.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_QUESTIONS_ESSAY.URL} exact component={RequirePermission(AdminQuestionsEssay, PATHS.ADMIN_QUESTIONS_ESSAY.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_QUESTIONS_MCQS.URL} exact component={RequirePermission(AdminQuestionsMcqs, PATHS.ADMIN_QUESTIONS_MCQS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_QUESTIONS_TEXT.URL} exact component={RequirePermission(AdminQuestionsText, PATHS.ADMIN_QUESTIONS_TEXT.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_QUESTIONS_COMPOSITE.URL} exact component={RequirePermission(AdminQuestionsComposite, PATHS.ADMIN_QUESTIONS_COMPOSITE.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_QUESTIONS_FILE.URL} exact component={RequirePermission(AdminQuestionsFile, PATHS.ADMIN_QUESTIONS_FILE.PERMISSIONS)} />

        <Route path={PATHS.ADMIN_QUESTIONS_SIZES.URL} exact component={RequirePermission(AdminSizes, PATHS.ADMIN_QUESTIONS_SIZES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_QUESTIONS_CATEGORIES.URL} exact component={RequirePermission(AdminCategories, PATHS.ADMIN_QUESTIONS_CATEGORIES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_QUESTIONS_DIFFICULTIES.URL} exact component={RequirePermission(AdminDifficulties, PATHS.ADMIN_QUESTIONS_DIFFICULTIES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_UPLOADS_VIDEOS.URL} exact component={RequirePermission(AdminUploadVideos, PATHS.ADMIN_UPLOADS_VIDEOS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_UPLOADS_BOOKS.URL} exact component={RequirePermission(AdminUploadBooks, PATHS.ADMIN_UPLOADS_BOOKS.PERMISSIONS)} />

        <Route path={PATHS.ADMIN_REPORTS_PAYMENTS.URL} exact component={RequirePermission(AdminPaymentsReport, PATHS.ADMIN_REPORTS_PAYMENTS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_MANUAL_PAYMENTS.URL} exact component={RequirePermission(AdminManualPayments, PATHS.ADMIN_MANUAL_PAYMENTS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_REPORTS_CONTENTS.URL} exact component={RequirePermission(AdminContentReports, PATHS.ADMIN_REPORTS_CONTENTS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_USER_PERFORMANCE.URL} exact component={RequirePermission(AdminUserPerformance, PATHS.ADMIN_USER_PERFORMANCE.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_USERS_PERFORMANCES.URL} exact component={RequirePermission(AdminUsersPerformances, PATHS.ADMIN_USERS_PERFORMANCES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_SETTINGS_IPLOGS.URL} exact component={RequirePermission(AdminIpLogs, PATHS.ADMIN_SETTINGS_IPLOGS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_MOCK_EXAMS.URL} exact component={RequirePermission(AdminMockExams, PATHS.ADMIN_MOCK_EXAMS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_EXAMS.URL} exact component={RequirePermission(AdminExams, PATHS.ADMIN_EXAMS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_SETTINGS_NOTIFICATIONS.URL} exact component={RequirePermission(AdminNotifications, PATHS.ADMIN_SETTINGS_NOTIFICATIONS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_EXAM_CORRECTION.URL} exact component={RequirePermission(AdminExamCorrection, PATHS.ADMIN_EXAM_CORRECTION.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_COURSES_CALENDAR.URL} exact component={RequirePermission(AdminCoursesCalendar, PATHS.ADMIN_COURSES_CALENDAR.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_MESSAGES.URL} exact component={RequirePermission(AdminMessages, PATHS.ADMIN_MESSAGES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_HONOR_ROLL.URL} exact component={RequirePermission(AdminHonorRoll, PATHS.ADMIN_HONOR_ROLL.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_POLICY_TERMS.URL} exact component={RequirePermission(AdminPolicyTermsSettings, PATHS.ADMIN_POLICY_TERMS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_BLOGS.URL} exact component={RequirePermission(AdminBlogs, PATHS.ADMIN_BLOGS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_BLOGS_CATEGORIES.URL} exact component={RequirePermission(AdminBlogsCategories, PATHS.ADMIN_BLOGS_CATEGORIES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_BLOGS_TAGS.URL} exact component={RequirePermission(AdminBlogsTags, PATHS.ADMIN_BLOGS_TAGS.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_COMPANIES.URL} exact component={RequirePermission(AdminCompanies, PATHS.ADMIN_COMPANIES.PERMISSIONS)} />

        <Route path={PATHS.ADMIN_CERT_ATTENDANCE.URL} exact component={RequirePermission(AdminCertAttendance, PATHS.ADMIN_CERT_ATTENDANCE.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_CERT_USER_ATTENDANCE.URL} exact component={RequirePermission(AdminCertUserAttendance, PATHS.ADMIN_CERT_USER_ATTENDANCE.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_BADGES.URL} exact component={RequirePermission(AdminBadges, PATHS.ADMIN_BADGES.PERMISSIONS)} />
        <Route path={PATHS.ADMIN_USER_BADGES.URL} exact component={RequirePermission(AdminUserBadges, PATHS.ADMIN_USER_BADGES.PERMISSIONS)} />




        <Route path={PATHS.USER_NOTIFICATIONS.URL} exact component={RequireAuth(UserNotifications)} />
        <Route path={PATHS.LANDING_PROFILE.URL} exact component={RequireAuth(Profile)} />


        {/* Invalid Routes */}
        <Route>
            <Route path="*" exact component={AdminDashboard} />
            <Redirect from='*' to={PATHS.ADMIN_DASHBOARD.URL} />
        </Route>

    </Switch>;
};

export default React.memo(AdminStack);
