import { Consts } from "../res";

const { READ, ADD } = Consts.PERMISSIONS;
const { ADMIN_DASHBOARD, ACCESS_MANAGE, BLOGS, CERTIFICATES,
    COURSES, COURSES_CALENDAR, EXMAS, HONORS, INSTRUCTORS, ISSUES_REPORTS,
    NEWS, PAYMENTS, POLICIES, QUESTIONS, SETTINGS, SIGN_IN_LOGS, STUDENT_PERFORMANCE,
    SUBSCRIPTIONS, SUCCESS_STORIES, TOPICS, UNITS, USERS, USERS_EXAMS_ROUNDS, USERS_MSGS,
    BOOKS, FAQS, VIDEOS, OVERALL_PERFORMANCE, COMPANIES
} = Consts.ADMIN_RESOURCES;

export default {
    LANDING_HOME: { URL: '/' },
    LANDING_CERTIFICATIONS: { URL: '/certifications' },
    LANDING_CERTIFICATIONS_DETAILS: { URL: '/certifications/:certId/:section' },
    LANDING_CERTIFICATIONS_DETAILS_NO_SECTION: { URL: '/certifications/:certId' },
    LANDING_WHYUS: { URL: '/whyus' },
    LANDING_COURSES: { URL: '/courses' },
    LANDING_COURSE_DETAILS: { URL: '/courses/:courseId/:packageId/:section' },
    LANDING_COURSE_DETAILS_NO_SECTION: { URL: '/courses/:courseId/:packageId' },
    LANDING_STAFF: { URL: '/staff' },
    LANDING_CONTACTUS: { URL: '/contactus' },
    LANDING_RESET_PASSWORD: { URL: '/resetpassword/:resetCode' },
    LANDING_ACTIVATION: { URL: '/activation' },
    LANDING_SIGNIN: { URL: '/signin' },
    LANDING_SIGNUP: { URL: '/signup' },
    LANDING_FORGOT_PASSWORD: { URL: '/forgotPassword' },
    LANDING_PROFILE: { URL: '/profile' },
    LANDING_TERMS_AND_CONDITIONS: { URL: '/termsandconditions' },
    LANDING_PRIVACY_POLICY: { URL: '/privacypolicy' },
    LANDING_HONOR: { URL: '/honor' },
    LANDING_CALENDAR: { URL: '/calendar' },
    LANDING_BLOGS: { URL: '/blogs' },
    LANDING_BLOG_DETAILS: { URL: '/blogs/:id' },
    LANDING_PROMISES: { URL: '/promises' },
    LANDING_COMPANIES: { URL: '/companies' },
    LANDING_BADGES: { URL: '/badge/release/:releaseId' },



    USER_DASHBOARD: { URL: '/user/dashboard' },
    USER_SUBSCRIPTIONS: { URL: '/user/subscriptions' },
    USER_COURSE_DETAILS: { URL: '/user/course/details' },
    USER_PERFORMANCE: { URL: '/user/performance' },
    USER_BANK_MCQS: { URL: '/user/msqsbank' },
    USER_BANK_ESSAY: { URL: '/user/essaysbank' },
    USER_BANK_TBS: { URL: '/user/tbsbank' },
    USER_BANK_FILE: { URL: '/user/filebank' },
    USER_MOCK_EXAM: { URL: '/user/mockexam' },
    USER_TEST_KNOWLEDGE_EXAM: { URL: '/user/exam/:topicId/:examType/:questionsCount' },
    USER_FREQ_EXAM: { URL: '/user/exam/:examId' },
    USER_EXAM_LOG: { URL: '/user/exam/log' },
    USER_EXAM_CORRECTION: { URL: '/user/exams/correction/:examId/:examRoundId' },
    USER_EXAM_RESUTLS: { URL: '/user/exams/results/:examId/:examRoundId' },
    USER_SUPPORT: { URL: '/user/support' },
    USER_NOTIFICATIONS: { URL: '/user/notifications' },
    USER_BADGES_ATTENDANCE: { URL: '/user/badgesAttendance' },
    USER_BADGES: { URL: '/user/badges' },


    ADMIN_DASHBOARD: { URL: '/admin/dashboard', PERMISSIONS: [[ADMIN_DASHBOARD, READ]] },
    ADMIN_ADMINS: { URL: '/admin/admins', PERMISSIONS: [[USERS, READ]] },
    ADMIN_USERS: { URL: '/admin/users', PERMISSIONS: [[USERS, READ]] },
    ADMIN_NEWS: { URL: '/admin/news', PERMISSIONS: [[NEWS, READ]] },
    ADMIN_INSTRUCTORS: { URL: '/admin/instructors', PERMISSIONS: [[INSTRUCTORS, READ]] },
    ADMIN_TEAM_MEMBERS: { URL: '/admin/teamMembers', PERMISSIONS: [[INSTRUCTORS, READ]] },
    ADMIN_FAQS: { URL: '/admin/faqs', PERMISSIONS: [[FAQS, READ]] },
    ADMIN_SUCCESS_STORIES: { URL: '/admin/successStories', PERMISSIONS: [[SUCCESS_STORIES, READ]] },
    ADMIN_ROLES: { URL: '/admin/roles', PERMISSIONS: [[ACCESS_MANAGE, READ]] },
    ADMIN_SUBSCRIPTIONS: { URL: '/admin/subscriptions', PERMISSIONS: [[SUBSCRIPTIONS, READ]] },
    ADMIN_PROMOTIONS: { URL: '/admin/promotions', PERMISSIONS: [[SUBSCRIPTIONS, READ]] },
    ADMIN_PACKAGES: { URL: '/admin/packages', PERMISSIONS: [[SUBSCRIPTIONS, READ]] },
    ADMIN_PACKAGES_POLICIES: { URL: '/admin/packages/policies', PERMISSIONS: [[POLICIES, READ]] },
    ADMIN_PACKAGES_CATEGORIES: { URL: '/admin/packages/categories', PERMISSIONS: [[SUBSCRIPTIONS, READ]] },
    ADMIN_CERTIFICATIONS: { URL: '/admin/certifications', PERMISSIONS: [[CERTIFICATES, READ]] },
    ADMIN_COURSES: { URL: '/admin/courses', PERMISSIONS: [[COURSES, READ]] },
    ADMIN_ONE_DAY_COURSES: { URL: '/admin/oneDayCourses', PERMISSIONS: [[COURSES, READ]] },
    ADMIN_UNITS: { URL: '/admin/units', PERMISSIONS: [[UNITS, READ]] },
    ADMIN_TOPICS: { URL: '/admin/topics', PERMISSIONS: [[TOPICS, READ]] },
    ADMIN_QUESTIONS_MCQS_NEW: { URL: '/admin/questions/mcqs/new', PERMISSIONS: [[QUESTIONS, ADD]] },
    ADMIN_QUESTIONS_ESSAY: { URL: '/admin/questions/essay', PERMISSIONS: [[QUESTIONS, READ]] },
    ADMIN_QUESTIONS_MCQS: { URL: '/admin/questions/mcqs', PERMISSIONS: [[QUESTIONS, READ]] },
    ADMIN_QUESTIONS_TEXT: { URL: '/admin/questions/text', PERMISSIONS: [[QUESTIONS, READ]] },
    ADMIN_QUESTIONS_COMPOSITE: { URL: '/admin/questions/composite', PERMISSIONS: [[QUESTIONS, READ]] },
    ADMIN_QUESTIONS_FILE: { URL: '/admin/questions/file', PERMISSIONS: [[QUESTIONS, READ]] },
    ADMIN_QUESTIONS_SIZES: { URL: '/admin/sizes', PERMISSIONS: [[QUESTIONS, READ]] },
    ADMIN_QUESTIONS_CATEGORIES: { URL: '/admin/categories', PERMISSIONS: [[QUESTIONS, READ]] },
    ADMIN_QUESTIONS_DIFFICULTIES: { URL: '/admin/difficulties', PERMISSIONS: [[QUESTIONS, READ]] },
    ADMIN_UPLOADS_VIDEOS: { URL: '/admin/uploads/videos', PERMISSIONS: [[VIDEOS, READ], [TOPICS, READ]] },
    ADMIN_UPLOADS_BOOKS: { URL: '/admin/uploads/books', PERMISSIONS: [[BOOKS, READ], [TOPICS, READ]] },
    ADMIN_REPORTS_PAYMENTS: { URL: '/admin/reports/payments', PERMISSIONS: [[PAYMENTS, READ]] },
    ADMIN_MANUAL_PAYMENTS: { URL: '/admin/payments/manual', PERMISSIONS: [[PAYMENTS, READ]] },
    ADMIN_REPORTS_CONTENTS: { URL: '/admin/reports/content/:reportOn', PERMISSIONS: [[ISSUES_REPORTS, READ]] },
    ADMIN_USER_PERFORMANCE: { URL: '/admin/reports/userPerformance', PERMISSIONS: [[STUDENT_PERFORMANCE, READ], [COURSES, READ], [USERS, READ]] },
    ADMIN_USERS_PERFORMANCES: { URL: '/admin/reports/usersPerformances', PERMISSIONS: [[OVERALL_PERFORMANCE, READ], [COURSES, READ]] },
    ADMIN_SETTINGS_IPLOGS: { URL: '/admin/settings/iplogs', PERMISSIONS: [[SIGN_IN_LOGS, READ]] },
    ADMIN_MOCK_EXAMS: { URL: '/admin/exams/mock', PERMISSIONS: [[EXMAS, READ]] },
    ADMIN_EXAMS: { URL: '/admin/exams', PERMISSIONS: [[EXMAS, READ]] },
    ADMIN_SETTINGS_NOTIFICATIONS: { URL: '/admin/settings/notifications', PERMISSIONS: [[SETTINGS, READ]] },
    ADMIN_EXAM_CORRECTION: { URL: '/admin/exams/correction', PERMISSIONS: [[USERS_EXAMS_ROUNDS, READ], [COURSES, READ]] },
    ADMIN_COURSES_CALENDAR: { URL: '/admin/calendar', PERMISSIONS: [[COURSES_CALENDAR, READ]] },
    ADMIN_MESSAGES: { URL: '/admin/messages', PERMISSIONS: [[USERS_MSGS, READ]] },
    ADMIN_HONOR_ROLL: { URL: '/admin/honor', PERMISSIONS: [[HONORS, READ]] },
    ADMIN_POLICY_TERMS: { URL: '/admin/policyTerms', PERMISSIONS: [[SETTINGS, READ]] },
    ADMIN_BLOGS: { URL: '/admin/blogs', PERMISSIONS: [[BLOGS, READ]] },
    ADMIN_BLOGS_CATEGORIES: { URL: '/admin/blog/categories', PERMISSIONS: [[BLOGS, READ]] },
    ADMIN_BLOGS_TAGS: { URL: '/admin/blog/tags', PERMISSIONS: [[BLOGS, READ]] },
    ADMIN_COMPANIES: { URL: '/admin/companies', PERMISSIONS: [[COMPANIES, READ]] },

    ADMIN_CERT_ATTENDANCE: { URL: '/admin/certAttendance', PERMISSIONS: [[COURSES, READ]] },
    ADMIN_CERT_USER_ATTENDANCE: { URL: '/admin/certUserAttendance', PERMISSIONS: [[COURSES, READ]] },
    ADMIN_BADGES: { URL: '/admin/badges', PERMISSIONS: [[COURSES, READ]] },
    ADMIN_USER_BADGES: { URL: '/admin/userBadges', PERMISSIONS: [[COURSES, READ]] },

};